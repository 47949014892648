import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Fetch from "../../controller/Fetch";
import Crumb from "../../controller/Crumb";
import Loading from "../../controller/Loading";
import AlertBox from "../../controller/AlertBox";
import { Button, Card, Col, ListGroup, Nav, Row, Tab, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Escape from "../../controller/Escape";

export default function Folder(){
    const {student,id,text}=useParams();
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(true);
    const [Text,setText]=useState(text);
    const [File,setFile]=useState();
    const [BCrumb,setBCrumb]=useState();

    useEffect(()=>{
        let send={
            url:`v1/student/course/${student}/text/${id}`,
            method:'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem(data['payloads']);
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
            }
            setVisible(false);
        }).catch(e=>{
            console.log(e)
            setVisible(false);
            setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
        })
    },[])
    useEffect(()=>{
        setText(text)
        if(!text){
            setBCrumb();
        }
    },[text])
    function openText(TextName,watch,TextId){
        setBCrumb(TextName);
        if(watch==0){
            changeWatchCondition(TextId,1);
        }
    }
    function changeText(e){
        setText(e.target.dataset.id);
        setBCrumb(Item['texts'][e.target.dataset.index]['TextName']);
        setFile();
        if(e.target.dataset.watch==0){
            changeWatchCondition(e.target.dataset.id,1);
        }
    }
    function showFile(e){
        setFile(e.target.dataset.id)
    }
    function doWatched(e){
        changeWatchCondition(Text,2,e.target);
    }
    function changeWatchCondition(TextId,type,element=false){
        let send={
            url:`v1/student/course/${student}/text/${TextId}`,
            method:'PUT',
            body:{
                type:type
            }
        }
        Fetch(send).then(data=>{
            if(data['result']){
                if(element){
                    element.remove();
                }
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
            }
        }).catch(e=>{
            console.log(e);
            setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
        })
    }
    return (
        <>
            {Item?<Crumb CurrentText={BCrumb?BCrumb:Item['FolderName']} items={BCrumb?[{index:0,href:'/',text:'ホーム'},{index:1,href:`/course/${student}`,text:Item['CourseName']},{index:2,href:`/course/${student}/folder/${id}`,text:Item['FolderName']}]:[{index:0,href:'/',text:'ホーム'},{index:1,href:`/course/${student}`,text:Item['CourseName']}]} />:""}
            {Alert ? <AlertBox type={Alert['type']} msgs={Alert['msgs']} visible={true} />:""}
            {Visible ?<Loading visible={true} />:""}{Item?
            <Tab.Container id="folderText"  activeKey={Text}>
                <Row>
                    <Col sm="auto">
                        <Nav variant="pills" className="flex-column">{Item['texts'].map((text,index)=>
                            <Nav.Item key={text['TextId']}>
                                <Link to={`/course/${student}/folder/${id}/text/${text['TextId']}`} data-id={text['TextId']} data-index={index} data-watch={text['watched']} className={"nav-link"+(text['TextId']==Text?" active":"")} key={text['TextId']} onClick={changeText}>
                                {text['TextName']}
                                {(text['TextId']==Text && BCrumb==undefined)?openText(text['TextName'],text['watched'],text['TextId']):""}
                                </Link>
                            </Nav.Item>)}
                        </Nav>
                    </Col>
                    <Col sm>
                        <Tab.Content>{Item['texts'].map(text=>
                            <Tab.Pane eventKey={text['TextId']} key={text['TextId']}>
                                <Card>
                                    <Card.Header className="w-100">
                                        <Row className="justify-content-between w-100">
                                            <Col sm="auto">
                                                <Card.Title>
                                                    {text['TextName']}
                                                </Card.Title>
                                            </Col>
                                            <Col sm="auto">{text['watched']==2?"":
                                                <Button variant="primary" onClick={doWatched}>
                                                    視聴完了
                                                </Button>}
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body>{text['video']['link']?
                                        <div>
                                            <h4>動画</h4>
                                            <iframe src={text['video']['link']} style={{height:'600px'}} className="w-100" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
                                            <div className="text-end">
                                                <a href={text['video']['link']} target="_blank">{text['video']['LinkName']?text['video']['LinkName']:text['video']['link']}</a>
                                            </div>
                                            <div>
                                                {Escape(text['video']['explanation'])}
                                            </div>
                                            <hr />
                                        </div>:""}{text['files'].length?
                                        <div>
                                            <h4>添付ファイル</h4>
                                            <Row>
                                                <Col sm="6">{(text['TextId']==Text && !isNaN(File))?
                                                    <Card>
                                                        <Card.Header>
                                                            <Card.Title>{text['files'][File]['FileName']}</Card.Title>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <iframe src={text['files'][File]['link']} className="iframe-file" />
                                                        </Card.Body>
                                                        <Card.Footer className="text-end">
                                                            <a href={text['files'][File]['link']} target="_blank" className="btn btn-secondary">新しいタブで開く</a>
                                                        </Card.Footer>
                                                    </Card>:""}
                                                </Col>
                                                <Col sm="6">
                                                    <ListGroup>{text['files'].map((file,index)=>
                                                        <ListGroup.Item action data-id={index} key={index} onClick={showFile}>
                                                            {file['FileName']}
                                                        </ListGroup.Item>)}
                                                    </ListGroup>
                                                </Col>
                                            </Row>
                                            <hr />
                                        </div>:""}
                                        <div>
                                            <h4>
                                                教材情報
                                            </h4>
                                            <Table striped responsive>
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            講義方法
                                                        </th>
                                                        <td>
                                                            {text['ClassMethod']}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            備考
                                                        </th>
                                                        <td>
                                                            {Escape(text['note'])}
                                                        </td>
                                                    </tr>{text['free'] &&
                                                    <tr>
                                                        <th>
                                                            体験教材
                                                        </th>
                                                        <td>
                                                            この教材は無料で受講できます。
                                                        </td>
                                                    </tr>}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Tab.Pane>)}
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>:""}
        </>
    );
}