import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, ListGroup, Modal, Row, Table } from "react-bootstrap";
import { useParams } from "react-router";
import AlertBox from "../../controller/AlertBox";
import Crumb from "../../controller/Crumb";
import Escape from "../../controller/Escape";
import Fetch from "../../controller/Fetch";
import { ConvertToDisplayDate } from "../../controller/keys";
import Loading from "../../controller/Loading";

let timer;
let limit;
export default function Info(){
    const {student,id}=useParams();
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(true);
    const [Exam,setExam]=useState(false);
    const [Timer,setTimer]=useState(0);
    const [Info,setInfo]=useState();
    const ExamArea=useRef();

    useEffect(()=>{
        Initialize(true);
    },[])
    function Initialize($check=false){
        setVisible(true);
        let send={
            url:`v1/student/course/${student}/examination/${id}`,
            method:'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem(data['payloads']);
                if($check && !data['payloads']['questions']){
                    setAlert({type:'info',msgs:["公開期間ではないもしくは受講が修了しているため，提出できません"]})
                }
                limit=data['payloads']['limit']*60;
                setTimer(limit)
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
            }
            setVisible(false)
            setExam(false)
        }).catch(e=>{
            console.log(e)
            setAlert({type:'danger',msgs:['通信エラーが発生しました。']})
            setVisible(false)
        })
    }
    function start(){
        setExam(true)
        timer=setInterval(()=>{
            limit-=1;
            setTimer(limit);
            if(limit<0){
                window.alert("制限時間が来たため，提出します。");
                doEntry();
            }else if(limit<=60){
                setInfo('残り時間が1分を切りました。')
            }
        },1000)
    }
    function convertTime(time){
        let min=Math.floor(time % 3600 /60);
        let rem=time % 60;
        return "残り"+(min?`${min}分`:"")+rem+"秒";
    }
    function doSubmit(){
        if(window.confirm('提出してもよろしいですか？')){
            doEntry();
        }
    }
    function doEntry(){
        setVisible(true);
        clearInterval(timer)
        let body=[];
        let parent=ExamArea.current;
        let data=parent.getElementsByClassName('js-data-txt')
        for(let i=0;i<data.length;++i){
            body.push({"QuestionId":data[i].dataset.id,'answer':data[i].value});
        }
        data=parent.getElementsByClassName('js-data-opt');
        for(let i=0;i<data.length;++i){
            let opts=data[i].getElementsByClassName('js-opt');
            let items=[];
            for(let n=0;n<opts.length;++n){
                if(opts[n].checked){
                    items.push(Number(opts[n].value));
                }
            }
            body.push({"QuestionId":data[i].dataset.id,'answer':items});
        }
        let send={
            'url':'v1/student/course/'+student+'/examination/'+id,
            'method':'PUT',
            'body':{
                'answers':body
            }
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({'type':'success','msgs':["提出しました。"]});
                setItem();
                Initialize();
            }else{
                setAlert({'type':'danger','style':true,'msgs':data['error']['description']});
                setVisible(false);
                setExam(false);
            }
        }).catch(error=>{
            console.log(error);
            setVisible(false);
            setExam(false);
            setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
        });
    }
    return (
        <>
            {Item?<Crumb CurrentText={Item['ExaminationTitle']} items={[{index:0,href:'/',text:'ホーム'}].concat({index:1,href:`/course/${student}`,text:Item['CourseName']})} />:""}
            {Alert ? <AlertBox type={Alert['type']} msgs={Alert['msgs']} visible={true} />:""}
            {Visible ?<Loading visible={true} />:""}{Item?
            <div>
                <div className="mb-4">
                    <h2>
                        {Item['ExaminationTitle']}
                    </h2>
                    <Table striped responsive>
                        <tbody>
                            <tr>
                                <th>
                                備考
                                </th>
                                <td>
                                    {Escape(Item['note'])}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                解答期限
                                </th>
                                <td>
                                {ConvertToDisplayDate(Item['deadline'],true)}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                複数回受験
                                </th>
                                <td>
                                {Item['multi'] ? <span>許可</span>:<span>許可されていません</span>}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                制限時間
                                </th>
                                <td>
                                {Item['limit']}分
                                </td>
                            </tr>
                            <tr>
                                <th>
                                得点
                                </th>
                                <td>
                                {Item['score']}点
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>{Item['editable'] && Item['questions']?
                <div className="my-4">
                    <Button variant="primary" onClick={start}>テスト開始</Button>
                    <Modal fullscreen={true} show={Exam} onHide={doSubmit} scrollable>
                        <Modal.Header>
                            <Modal.Title className="row justify-content-between w-100">
                                <Col sm="auto">
                                    {Item['ExaminationTitle']}
                                </Col>{Info?
                                <Col className="bg-info text-center">
                                    {Info}
                                </Col>:""}
                                <Col sm="auto" className={Timer<30?"bg-danger":(Timer<60?"bg-warning":"")}>
                                    {convertTime(Timer)}
                                </Col>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ol ref={ExamArea}>{Item['questions'].map(quiz=>
                                <li key={quiz['QuestionId']} className="mb-2">
                                    <Card>
                                        <Card.Header>
                                            <Card.Title>
                                                {quiz['question']}
                                            </Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <ConvertMethod item={quiz} />
                                        </Card.Body>
                                    </Card>
                                </li>)}</ol>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={doSubmit}>
                                提出
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>:""}
                <div className="mt-4">
                    <h3>
                        受験済みテスト
                    </h3>
                    <ListGroup>{Item['submits'].map(submit=>
                        <ListGroup.Item key={submit['StudentExaminationId']}>
                            {ConvertToDisplayDate(submit['SubmitDatetime'],true)} 提出
                        </ListGroup.Item>)}
                    </ListGroup>
                </div>
            </div>:""}
        </>
    );
}
function ConvertMethod({item}){
    switch(item['method']){
        case 'ShortText':
            return (<input type="text" name={item['QuestionId']} className="form-control js-data-txt" data-id={item['QuestionId']} placeholder="回答を入力してください" />);
            break;
        case 'LongText':
            return (<textarea name={item['QuestionId']} className="form-control js-data-txt" data-id={item['QuestionId']} placeholder="回答を入力してください" />);
            break;
        case 'radio':
        case 'checkbox':
            return(
                <div className="row js-data-opt" data-id={item['QuestionId']}>
                    {item['options'].map((opt,index)=><Options type={item['method']} index={item['QuestionId']} option={opt} optIndex={index} key={index} />)}
                </div>
            );
            break;
        case 'supplementation':
        default:
            return(<span></span>);
    }
}
function Options(props){
    return (
        <div className="col-auto me-2">
            <input type={props.type} name={props.index} id={"question_"+props.index+'_'+props.optIndex} className="form-check-input js-opt" data-id={props.index} value={props.optIndex} key={props.optIndex} />
            <label htmlFor={"question_"+props.index+'_'+props.optIndex} className="form-check-label">
                {props.option}
            </label>
        </div>
    );
}