import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Footer from './view/common/Footer';
import Header from './view/common/Header';
import Detail from './view/course/detail';
import Examination from './view/course/examination';
import Folder from './view/course/folder';
import Info from './view/course/info';
import Line from './view/course/line';
import Report from './view/course/report';
import Home from './view/Home';

export default function App() {
	return (
		<BrowserRouter basename='/'>
			<Header />
			<main className='document__main' role="main">
				<div className='container h-100'>
					<div className='row h-100'>
						<div className='col-12 h-100'>
							<Routes>
								<Route path='/'>
									<Route index element={<Home />} />
								</Route>
								<Route path="/course">
									<Route path=':student'>
										<Route index element={<Detail />} />
										<Route path='contact/:id' element={<Info />} />
										<Route path='report/:id' element={<Report />} />
										<Route path='examination/:id' element={<Examination />} />
										<Route path='folder/:id'>
											<Route index element={<Folder />} />
											<Route path='text/:text' element={<Folder />} />
										</Route>
									</Route>
									<Route path='line' element={<Line />} />
								</Route>
							</Routes>
						</div>
					</div>
				</div>
			</main>
			<Footer />
		</BrowserRouter>
	);
}
