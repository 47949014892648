import { useEffect, useState } from "react";
import { Card, Col, ListGroup, Modal, Row } from "react-bootstrap";
import { useParams } from "react-router";
import AlertBox from "../../controller/AlertBox";
import Crumb from "../../controller/Crumb";
import Escape from "../../controller/Escape";
import Fetch from "../../controller/Fetch";
import { ContactType, ConvertToDisplayDate } from "../../controller/keys";
import Loading from "../../controller/Loading";

export default function Info(){
    const {student,id}=useParams();
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(true);
    const [File,setFile]=useState('notShow');
    useEffect(()=>{
        let send={
            url:`v1/student/course/${student}/contact/${id}`,
            method:'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem(data['payloads']);
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
            }
            setVisible(false)
        }).catch(e=>{
            console.log(e)
            setAlert({type:'danger',msgs:['通信エラーが発生しました。']})
            setVisible(false)
        })
    },[])
    function showFile(e){
        setFile(e.target.dataset.id?Number(e.target.dataset.id):'notShow')
    }
    function closeModal(){
        setFile('notShow');
    }
    return (
        <>
            <Crumb CurrentText={Item?Item['ContactTitle']:"おしらせ"} items={[{index:0,href:'/',text:'ホーム'}].concat({index:1,href:`/course/${student}`,text:Item?Item['CourseName']:"コースページ"})} />
            {Alert ? <AlertBox type={Alert['type']} msgs={Alert['msgs']} visible={true} />:""}
            {Visible ?<Loading visible={true} />:""}{Item?
            <div>
                <Card>
                    <Card.Header>
                        <Card.Title>
                            {Item['ContactTitle']}
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <div className="mb-3">
                            {Escape(Item['ContactContent'])}
                        </div>
                        <ListGroup>{Item['files'].map((file,index)=>
                            <ListGroup.Item action onClick={showFile} key={index} data-id={index}>
                                {file['FileName']}
                            </ListGroup.Item>)}
                        </ListGroup>
                    </Card.Body>
                    <Card.Footer>
                        <p>
                            掲載日時：{ConvertToDisplayDate(Item['PostedDatetime'],true)}
                        </p>
                        <p>
                            掲載者：{Item['EntriedUserName']}
                        </p>
                        <p>
                            カテゴリー：{ContactType(Item['ContactType'])}
                        </p>
                        <p>
                            コース名：{Item['CourseName']}
                        </p>
                    </Card.Footer>
                </Card>{!isNaN(File)?
                <Modal size="xl" show={true} onHide={closeModal} centered scrollable>
                    <Modal.Header closeButton>
                        <Modal.Title>{Item['files'][File]['FileName']}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm="6">
                                <iframe src={Item['files'][File]['link']} className='iframe-file' />
                            </Col>
                            <Col sm="6">
                                <ListGroup>{Item['files'].map((file,index)=>
                                    <ListGroup.Item action onClick={showFile} key={index} data-id={index}>
                                        {file['FileName']}
                                    </ListGroup.Item>)}
                                </ListGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <a href={Item['files'][File]['link']} target="_blank" className="btn btn-secondary">新しいタブで開く</a>
                    </Modal.Footer>
                </Modal>:""}
            </div>:""}
        </>
    );
}