import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Fetch from "../../controller/Fetch";
import Crumb from "../../controller/Crumb";
import Loading from "../../controller/Loading";
import AlertBox from "../../controller/AlertBox";
import Caroucel from "../common/Caroucel";
import CardList from "../../controller/CardList";
import { ConvertToDisplayDate, CourseFree, Domain, StudentCondition } from "../../controller/keys";
import { Button, Card, ListGroup, Modal, Table } from "react-bootstrap";
import Escape from "../../controller/Escape";
import LineBtn from "../common/LineBtn";

export default function Detail(){
    const {student}=useParams();
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(true);
    const [ReportGrade,setReportGrade]=useState(false);
    const [ExamGrade,setExamGrade]=useState(false);
    const [Syllabus,setSyllabus]=useState(false);
    const [Contract,setContract]=useState(false);
    const [Cancellation,setCancellation]=useState(false);

    useEffect(()=>{
        let send={
            url:'v1/student/course/'+student,
            method:'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                if(data['payloads']['display']){
                    let tmp=data['payloads']['texts'];
                    let text=[];
                    for(let i=0;i<tmp.length;++i){
                        text.push({icon:tmp[i]['icon']['link'],text:tmp[i]['FolderName'],link:`/course/${student}/folder/${tmp[i]['FolderId']}`})
                        //text.push({icon:"https://canteach.jp/assets/img/icon192x192.png",text:tmp[i]['FolderName']+"0",link:`/course/${student}/text/${tmp[i]['FolderId']}`})
                    }
                    tmp=data['payloads']['contacts']
                    let contact=[];
                    for(let i=0;i<tmp.length;++i){
                        contact.push({index:i,href:`/course/${student}/contact/${tmp[i]['ContactId']}`,text:tmp[i]['ContactTitle']})
                    }
                    tmp=data['payloads']['reports']
                    let report=[];
                    for(let i=0;i<tmp.length;++i){
                        report.push({index:i,href:`/course/${student}/report/${tmp[i]['ReportId']}`,text:tmp[i]['ReportTitle']})
                    }
                    tmp=data['payloads']['examinations']
                    let examination=[];
                    for(let i=0;i<tmp.length;++i){
                        examination.push({index:i,href:`/course/${student}/examination/${tmp[i]['ExaminationId']}`,text:tmp[i]['ExaminationTitle']})
                    }
                    setItem(Object.assign(data['payloads'],{
                        CourseName:data['payloads']['CourseName'],
                        text:text,
                        contact:contact,
                        report:report,
                        examination:examination,
                    }));
                }else{
                    setItem();
                    setAlert({type:'danger',msgs:["以下のいずれかの理由でコースページを表示できません。","決済が完了していない可能性がある。(CanTeachと決済システムの連携に失敗した場合，講師に確認してもらってください)","コース受講終了後，講師によってコース情報の表示を制限されている可能性がある。(講師に直接ご連絡してください)"]})
                }
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
            }
            setVisible(false);
        }).catch(e=>{
            console.log(e)
            setVisible(false);
            setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
        })
    },[])
    function showReportGrade(){
        setReportGrade(!ReportGrade)
    }
    function showExamGrade(){
        setExamGrade(!ExamGrade)
    }
    function showSyllabus(){
        setSyllabus(!Syllabus);
    }
    function showContract(){
        setContract(!Contract);
    }
    function showCancellation(){
        setCancellation(!Cancellation);
    }
    function closeModal(){
        setReportGrade(false);
        setExamGrade(false);
        setSyllabus(false);
        setContract(false);
        setCancellation(false);
    }
    function applyCancel(){
        setVisible(true);
        let send={
            url:`v1/student/course/${student}`,
            method:'DELETE'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setAlert({type:'success',msgs:["解約申請をしました。"]})
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
            }
            window.scroll({top:0})
            setVisible(false)
            closeModal();
        }).catch(e=>{
            setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
            setVisible(false);
            console.log(e)
            closeModal();
        })
    }
    return (
        <>
            <Crumb CurrentText={Item?Item['CourseName']:"コース詳細"} items={[{index:0,href:'/',text:'ホーム'}]} />
            {(Item && Item['condition']>0)?<AlertBox type="info" visible="true" msgs={[StudentCondition(Item['condition'])+"しています"]} />:""}
            {Alert ? <AlertBox type={Alert['type']} msgs={Alert['msgs']} visible={true} />:""}
            {Visible ?<Loading visible={true} />:""}{Item?
            <div>
                {/* 教材フォルダ */}
                <Caroucel items={Item['text']} />
                {/*リスト*/}
                <div className="row">{Item['syllabus']['condition']['line'][student]?"":Item['syllabus']['ConnectLine']?
                    <div className="col-sm-6 mt-4">
                        <Card className="w-100">
                            <Card.Header>
                                公式LINE連携
                            </Card.Header>
                            <Card.Body>
                                <LineBtn Link={Item['syllabus']['ConnectLine']+`&state=${student}`} target="_blank" Text="公式LINEと連携" />
                            </Card.Body>
                        </Card>
                    </div>:""}
                    <div className="col-sm-6 mt-4">
                        <CardList title="お知らせ" items={Item['contact']} />
                    </div>
                    <div className="col-sm-6 mt-4">
                        <CardList title="課題" items={Item['report']} />
                    </div>
                    <div className="col-sm-6 mt-4">
                        <CardList title="テスト" items={Item['examination']} />
                    </div>
                    <div className="col-sm-6 mt-4">
                        <Card className="w-100">
                            <Card.Header>
                                成績
                            </Card.Header>
                            <Card.Body>
                                <ListGroup variant="flush">
                                    <ListGroup.Item action onClick={showReportGrade}>
                                        <div className="row">
                                            <div className="col">
                                                課題
                                            </div>
                                            <div className="col-auto">
                                                {Item['grades']['reports']['summary']['score']}/{Item['grades']['reports']['summary']['total']}点
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item action onClick={showExamGrade}>
                                        <div className="row">
                                            <div className="col">
                                                テスト
                                            </div>
                                            <div className="col-auto">
                                                {Item['grades']['examinations']['summary']['score']}/{Item['grades']['examinations']['summary']['total']}点
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-sm-6 mt-4">
                        <Card className="w-100">
                            <Card.Header>
                                その他
                            </Card.Header>
                            <Card.Body>
                                <ListGroup variant="flush">
                                    <a href={Domain("home",`account/payment/${student}`)} className="list-group-item list-group-item-action" target="_blank">
                                        決済履歴
                                    </a>
                                    <ListGroup.Item action onClick={showSyllabus}>
                                        シラバス
                                    </ListGroup.Item>
                                    <ListGroup.Item action onClick={showContract}>
                                        契約内容
                                    </ListGroup.Item>{Item['condition']==0?
                                    <ListGroup.Item action onClick={showCancellation}>
                                        解約申請
                                    </ListGroup.Item>:""}
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                {/*モーダル*/}
                <Modal show={(ReportGrade || ExamGrade)?true:false} onHide={closeModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{ReportGrade?"課題成績":"テスト成績"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table striped>
                            <thead>
                                <tr>
                                    <td>
                                        {ReportGrade?"課題名":"テスト名"}
                                    </td>
                                    <td>
                                        得点
                                    </td>
                                </tr>
                            </thead>
                            <tbody>{ReportGrade?Item['grades']['reports']['details'].map(detail=>
                                <tr key={detail['StudentReportId']}>
                                    <td>
                                        {detail['ReportTitle']}({detail['submit']?"提出済み":"未提出"})
                                    </td>
                                    <td>
                                        {detail['score']}/{detail['total']}点
                                    </td>
                                </tr>
                            ):Item['grades']['examinations']['details'].map(detail=>
                                <tr key={detail['StudentExaminationId']}>
                                    <td>
                                        {detail['ExaminationTitle']}({detail['submit']?"提出済み":"未提出"})
                                    </td>
                                    <td>
                                        {detail['score']}/{detail['total']}点
                                    </td>
                                </tr>
                            )}</tbody>
                        </Table>
                    </Modal.Body>
                </Modal>
                <Modal size="xl" show={Syllabus} onHide={closeModal} centered scrollable>
                    <Modal.Header closeButton>
                        <Modal.Title>シラバス</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="table-responsive">
                            <Table striped>
                                <tbody>
                                    <tr>
                                        <th>
                                        コース名
                                        </th>
                                        <td>
                                        {Item['syllabus']['CourseName']}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                        開講期間
                                        </th>
                                        <td>
                                        {ConvertToDisplayDate(Item['syllabus']['term'][0])}から{ConvertToDisplayDate(Item['syllabus']['term'][1])}まで
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                        講師
                                        </th>
                                        <td>
                                        <div className="list-group list-group-flush">
                                            {Item['syllabus']['teachers'].map((teacher,index)=><a href={"/profile/teacher/"+teacher['TeacherId']} className="list-group-item list-group-item-action" target="_blank" key={index}>{teacher.UserName}</a>)}
                                        </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                        講義方法
                                        </th>
                                        <td>
                                            {Escape(Item['syllabus']['CourseMethod'])}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                        受講レベル
                                        </th>
                                        <td>
                                            {Item['syllabus']['CourseLevel']}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                        キーワード
                                        </th>
                                        <td>
                                            {Item['syllabus']['keywords'].map(key=><span key={key}>{key}&nbsp;</span>)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                        最大受講者数
                                        </th>
                                        <td>
                                            {Item['syllabus']['MaxStudent']}名
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                        初期費用
                                        </th>
                                        <td>
                                            {Item['syllabus']['SetupPrice']}円
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                        月額費用
                                        </th>
                                        <td>
                                            {Item['syllabus']['MonthlyPrice']}円/月{Item['syllabus']['AccountNumber']?' × '+Item['syllabus']['AccountNumber']+'月':''}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                        無料期間
                                        </th>
                                        <td>
                                            {CourseFree(Item['syllabus']['free'])}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                        講義の目標
                                        </th>
                                        <td>
                                            {Escape(Item['syllabus']['goal'])}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                        講義計画
                                        </th>
                                        <td>
                                            <ol>
                                                {Item['syllabus']['plans'].map((plan,index)=><li key={index}>{plan}</li>)}
                                            </ol>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                        必要物
                                        </th>
                                        <td>
                                            <ul>
                                                {Item['syllabus']['needs'].map((need,index)=><li key={index}>{need}</li>)}
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                        受講の注意事項
                                        </th>
                                        <td>
                                            {Escape(Item['syllabus']['attention'])}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            契約書
                                        </th>
                                        <td>
                                            <a href={Item['syllabus']['contract']['link']} target="_blank">{Item['syllabus']['contract']['name']}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal size="xl" show={Contract} onHide={closeModal} centered scrollable>
                    <Modal.Header closeButton>
                        <Modal.Title>契約内容</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-sm-6">
                                <iframe src={Item['contracts']['file']['link']} className="iframe-file" />
                            </div>
                            <div className="col-sm-6">
                                <Table>
                                    <tbody>
                                        <tr>
                                            <th>
                                                同意日時
                                            </th>
                                            <td>
                                                {ConvertToDisplayDate(Item['contracts']['date'])}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                同意者名
                                            </th>
                                            <td>
                                                {Item['contracts']['name']}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={Cancellation} onHide={closeModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>解約申請</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            本当に解約申請をしますか？<br />
                            ※既に支払われた受講料は返金されません。
                        </p>
                    </Modal.Body>
                    <Modal.Footer>{Item['condition']==0?
                        <div>
                            <Button variant="danger" onClick={applyCancel}>
                                解約する
                            </Button>
                        </div>:""}
                    </Modal.Footer>
                </Modal>
            </div>:""}
        </>
    );
}