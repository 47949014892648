import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getRights } from "..";
import Fetch from "../controller/Fetch";
import AlertBox from "../controller/AlertBox";
import Loading from "../controller/Loading";
import CardList from "../controller/CardList";

export default function Home(){
    const [Visible,setVisible]=useState(true);
    const [Course,setCourse]=useState([]);
    const [Alert,setAlert]=useState();
    useEffect(()=>{
        let send={
            url:'v1/student',
            method:'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                let item=[];
                for(let i=0;i<data['payloads']['courses'].length;++i){
                    item.push({
                        index:i,
                        href:'/course/'+data['payloads']['courses'][i]['StudentId'],
                        text:data['payloads']['courses'][i]['CourseName']
                    });
                }
                setCourse(item);
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
            }
            setVisible(false);
        }).catch(e=>{
            console.log(e)
            setVisible(false);
            setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
        })
    },[])
    return (
        <div className="mt-3">
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />
            <div className="row">
                <div className="col-sm-6 col-md-6 mb-2">
                    <CardList title="受講中コース" items={Course} />
                </div>
            </div>
        </div>
    );
}