import { useEffect, useState } from "react";
import AlertBox from "../../controller/AlertBox";
import Crumb from "../../controller/Crumb";
import Fetch from "../../controller/Fetch";
import { getParams } from "../../controller/keys";
import Loading from "../../controller/Loading";

export default function Line(){
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(true);
    useEffect(()=>{
        let params=getParams();
        let send={
            url:`v1/student/course/${params['state']}/line`,
            method:'POST',
            body:params
        }
        Fetch(send).then(data=>{
            if(data['result']){
                window.alert('公式LINEと連携しました。');
                window.close();
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
            }
            setVisible(false)
        }).catch(e=>{
            console.log(e)
            setAlert({type:'danger',msgs:['通信エラーが発生しました。']})
            setVisible(false)
        })
    },[])
    return (
        <>
            <Crumb CurrentText={"LINE連携"} items={[{index:0,href:'/',text:'ホーム'}]} />
            {Alert ? <AlertBox type={Alert['type']} msgs={Alert['msgs']} visible={true} />:""}
            {Visible ?<Loading visible={true} />:""}
        </>
    );
}