import Card from 'react-bootstrap/Card'
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

export default function TextCarousel({items}){
    const toShow=items.length>3?4:items.length;
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: toShow,
        slidesToScroll: 1,
        className:'w-auto',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: toShow>3?3:toShow,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: toShow>2?2:toShow,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };
    const style={
        maxWidth:"272px"
    };
    return(
        <div className='m-4'>
            <Slider {...settings}>{items.map((item,index)=>
                <div className="px-2 mb-5 carousel__wrapper text-center" style={style} key={index}>
                    <Link to={item['link']} className="text-decoration-none" style={style}>
                        <Card>
                            <Card.Img variant="top" src={item['icon']} className="w-100 h-auto" />
                            <Card.Body>
                                <Card.Title className="text-center">
                                    {item['text']}
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Link>
                </div>)}
            </Slider>
        </div>
    );
}