import { useEffect, useRef, useState } from "react";
import { Button, Col, FormControl, ListGroup, Modal, Row, Table } from "react-bootstrap";
import { useParams } from "react-router";
import AlertBox from "../../controller/AlertBox";
import Crumb from "../../controller/Crumb";
import Escape from "../../controller/Escape";
import Fetch from "../../controller/Fetch";
import { ConvertToDisplayDate } from "../../controller/keys";
import Loading from "../../controller/Loading";
import UploadForm, { uploadFile } from "../../controller/UploadForm";

export default function Info(){
    const {student,id}=useParams();
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(true);
    const [File,setFile]=useState('notShow');
    const [Submit,setSubmit]=useState('notShow');
    const [SubmitFile,setSubmitFile]=useState();
    const [UploadFile,setUploadFile]=useState([]);

    const Comment=useRef();

    useEffect(()=>{
        Initialize();
    },[])
    function Initialize(){
        setVisible(true);
        let send={
            url:`v1/student/course/${student}/report/${id}`,
            method:'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setItem(data['payloads']);
                if(!data['payloads']['reports']['editable']){
                    setAlert({type:'info',msgs:["公開期間ではないもしくは受講が修了しているため，提出できません"]})
                }
            }else{
                setAlert({type:'danger',msgs:data['error']['description']})
            }
            setVisible(false)
        }).catch(e=>{
            console.log(e)
            setAlert({type:'danger',msgs:['通信エラーが発生しました。']})
            setVisible(false)
        })
    }
    function modalFile(e){
        setFile(e.target.dataset.id?Number(e.target.dataset.id):'notShow');
    }
    function modalSubmit(e){
        setSubmit(e.target.dataset.id?Number(e.target.dataset.id):'notShow');
        setSubmitFile();
    }
    function showSubmitFile(e){
        setSubmitFile(Number(e.target.dataset.id));
    }
    function closeModal(){
        setFile('notShow');
        setSubmit('notShow');
    }
    function doSubmit(){
        setVisible(true);
        uploadFile(UploadFile).then(data=>{
            if(data['result']){
                //アップロード成功
                let items=[];
                for(let i=0;i<data['UploadFile'].length;++i){
                    items.push(data['UploadFile'][i]['id'])
                }
                let send={
                    url:`v1/student/course/${student}/report/${id}`,
                    method:'PUT',
                    body:{
                        comment:Comment.current.value,
                        files:items
                    }
                }
                Fetch(send).then(data=>{
                    if(data['result']){
                        setItem();
                        setAlert({type:'success',msgs:["課題を提出しました。"]})
                        Initialize();
                    }else{
                        setAlert({type:"danger",msgs:data['error']['description']})
                        setVisible(false)
                    }
                }).catch(e=>{
                    console.log(e)
                    setAlert({type:"danger",msgs:["通信エラーが発生しました。"]})
                    setVisible(false)
                })
            }else{
                setAlert({type:"danger",msgs:data['error']['description']})
                setVisible(false)
            }
        }).catch(e=>{
            console.log(e)
            setAlert({type:"danger",msgs:["通信エラーが発生しました。"]})
            setVisible(false)
        })
    }
    return (
        <>
            {Item?<Crumb CurrentText={Item['reports']['ReportTitle']} items={[{index:0,href:'/',text:'ホーム'}].concat({index:1,href:`/course/${student}`,text:Item['reports']['CourseName']})} />:""}
            {Alert ? <AlertBox type={Alert['type']} msgs={Alert['msgs']} visible={true} />:""}
            {Visible ?<Loading visible={true} />:""}{Item?
            <div>
                <div className="mb-3">
                    <h2>
                        {Item['reports']['ReportTitle']}
                    </h2>
                    <Table striped>
                        <tbody>
                            <tr>
                                <th>
                                説明
                                </th>
                                <td>
                                {Escape(Item['reports']['content'])}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    提出期限
                                </th>
                                <td>
                                    {ConvertToDisplayDate(Item['reports']['deadline'],true)}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                添付ファイル
                                </th>
                                <td>
                                    <ListGroup>{Item['reports']['files'].map((file,index)=>
                                        <ListGroup.Item action data-id={index} key={index} onClick={modalFile}>
                                            {file['FileName']}
                                        </ListGroup.Item>)}
                                    </ListGroup>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                得点
                                </th>
                                <td>
                                {Item['reports']['score']}点
                                </td>
                            </tr>
                        </tbody>
                    </Table>{!isNaN(File)?
                    <Modal size="xl" show={true} onHide={closeModal} centered scrollable>
                        <Modal.Header closeButton>
                            <Modal.Title>{Item['reports']['files'][File]['FileName']}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col sm="6">
                                    <iframe src={Item['reports']['files'][File]['link']} className='iframe-file' />
                                </Col>
                                <Col sm="6">
                                    <ListGroup>{Item['reports']['files'].map((file,index)=>
                                        <ListGroup.Item action onClick={modalFile} key={index} data-id={index}>
                                            {file['FileName']}
                                        </ListGroup.Item>)}
                                    </ListGroup>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <a href={Item['reports']['files'][File]['link']} target="_blank" className="btn btn-secondary">新しいタブで開く</a>
                        </Modal.Footer>
                    </Modal>:""}
                </div>{Item['reports']['editable']?
                <div className="my-3">
                    <h3>
                        課題の提出
                    </h3>
                    <Table striped responsive>
                        <tbody>
                            <tr>
                                <th>
                                    添付ファイル
                                </th>
                                <td>
                                    <UploadForm setUploadFile={setUploadFile} />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    コメント
                                </th>
                                <td>
                                    <FormControl as="textarea" placeholder="コメント" ref={Comment} />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <Button variant="primary" onClick={doSubmit}>
                        提出
                    </Button>
                </div>:""}
                <div className="mt-3">
                    <h3>
                        提出済み課題
                    </h3>
                    <ListGroup>{Item['submits'].map((submit,index)=>
                        <ListGroup.Item action onClick={modalSubmit} data-id={index} key={index}>
                            {ConvertToDisplayDate(submit['SubmitDatetime'],true)}&nbsp;提出
                        </ListGroup.Item>)}
                    </ListGroup>{!isNaN(Submit)?
                    <Modal size="xl" show={true} onHide={closeModal} centered scrollable>
                        <Modal.Header closeButton>
                            <Modal.Title>提出内容詳細</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col sm="6">
                                    {Item['submits'][Submit]['answers']['file'][SubmitFile]?<iframe src={Item['submits'][Submit]['answers']['file'][SubmitFile]['link']} className='iframe-file' />:""}
                                </Col>
                                <Col sm="6">
                                    <Table striped>
                                        <thead>
                                            <tr>
                                                <td colSpan="2" className="text-center">
                                                    提出内容
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>
                                                    添付ファイル
                                                </th>
                                                <td>
                                                    <ListGroup>{Item['submits'][Submit]['answers']['file'].map((file,index)=>
                                                        <ListGroup.Item action data-id={index} onClick={showSubmitFile} key={index}>
                                                            {file['FileName']}
                                                        </ListGroup.Item>)}
                                                    </ListGroup>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    コメント
                                                </th>
                                                <td>
                                                    {Escape(Item['submits'][Submit]['answers']['comment'])}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    提出日時
                                                </th>
                                                <td>
                                                    {ConvertToDisplayDate(Item['submits'][Submit]['SubmitDatetime'],true)}
                                                </td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                            <tr>
                                                <td colSpan="2" className="text-center">
                                                    採点内容
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>
                                                    講評
                                                </th>
                                                <td>
                                                    {Escape(Item['submits'][Submit]['comment'])}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    得点
                                                </th>
                                                <td>
                                                    {Item['submits'][Submit]['score']}/{Item['submits'][Submit]['total']}点
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                        {Item['submits'][Submit]['answers']['file'][SubmitFile]?<a href={Item['submits'][Submit]['answers']['file'][SubmitFile]['link']} target="_blank" className="btn btn-secondary">新しいタブで開く</a>:""}
                        </Modal.Footer>
                    </Modal>:""}
                </div>
            </div>:""}
        </>
    );
}